import React from 'react';
import { AppBar, Avatar, makeStyles, Toolbar } from '@material-ui/core';
import { useUserStore } from '../stores/useUserStore';

const useStyles = makeStyles(theme => ({
	avatar: {
		marginRight: 0,
		marginLeft: 'auto',
		backgroundColor: '#f0527c',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0
		}
	},
	paper: {
		borderTop: 0,
		borderLeft: 0,
		borderRight: 0
	}
}));

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
	const classes = useStyles();
	const name = useUserStore(state => state.name);

	return (
		<AppBar position="fixed" color="default" elevation={0}>
			<Toolbar>
				<Avatar className={classes.avatar}>
					{name.charAt(0).toUpperCase()}
				</Avatar>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
