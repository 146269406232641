import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

interface LoadingProps {}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

const Loading: React.FC<LoadingProps> = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress />
		</div>
	);
};

export default Loading;
