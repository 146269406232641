import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from './pages/Loading';
const HomePage = lazy(() => import('./pages/HomePage'));
const Contact = lazy(() => import('./pages/Contact'));
const Settings = lazy(() => import('./pages/Settings'));

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route exact path="/">
					<HomePage />
				</Route>
				<Route exact path="/contact">
					<Contact />
				</Route>
				<Route exact path="/settings">
					<Settings />
				</Route>
			</Switch>
		</Suspense>
	);
};

export default Routes;
