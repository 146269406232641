import {
	OptionsObject,
	ProviderContext,
	SnackbarMessage,
	useSnackbar
} from 'notistack';

export enum Notification {
	JoiningRoom = 1,
	ChangingCamera
}

let useSnackbarRef: ProviderContext;

export const SnackbarUtilsConfigurator = () => {
	useSnackbarRef = useSnackbar();
	return null;
};

export const notify = (message: SnackbarMessage, options?: OptionsObject) => {
	useSnackbarRef.enqueueSnackbar(message, options);
};

export const notifyP = (
	key: Notification,
	message: SnackbarMessage,
	options?: OptionsObject
) => {
	useSnackbarRef.enqueueSnackbar(message, { ...options, key, persist: true });
};

export const closeNotice = (key: Notification) => {
	useSnackbarRef.closeSnackbar(key);
};
