import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField
} from '@material-ui/core';
import { useUserStore } from '../../stores/useUserStore';
import { useTranslation } from 'react-i18next';

interface LoginDialogProps {}

const LoginDialog: React.FC<LoginDialogProps> = () => {
	const [state, setState] = useState('');
	const name = useUserStore(state => state.name);
	const setName = useUserStore(state => state.setName);
	const { t } = useTranslation();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState(event.target.value);
	};

	const handleConfirm = () => {
		setName(state);
	};

	return (
		<Dialog open={!name} maxWidth="xs" fullWidth>
			<DialogTitle>{t('components.loginDialog.title')}</DialogTitle>
			<DialogContent>
				<TextField
					label={t('common.userName')}
					value={state}
					onChange={handleChange}
					fullWidth
					autoFocus
				/>
				<DialogContentText style={{ marginTop: '2em' }}>
					{t('components.loginDialog.content_1')}
					<br />
					{t('components.loginDialog.content_2')}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleConfirm} disabled={!state}>
					{t('common.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default LoginDialog;
