import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { darkModeAtom, muiThemeAtom } from '../atoms';

interface ThemeProviderProps {}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
	const [, setDarkMode] = useAtom(darkModeAtom);
	const [muiTheme] = useAtom(muiThemeAtom);
	const mediaQueryDark = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		const darkMode = window.localStorage.getItem('dark-mode');

		if (darkMode) {
			setDarkMode(darkMode === '1');
		} else {
			setDarkMode(mediaQueryDark);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaQueryDark]);

	return (
		<MuiThemeProvider theme={muiTheme}>
			{children}
			<CssBaseline />
		</MuiThemeProvider>
	);
};

export { ThemeProvider };
