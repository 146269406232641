import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './layout/Header';
import Container from './layout/Container';
import BottomNavigationBar from './layout/BottomNavigationBar';
import Routes from './Routes';
import Loading from './pages/Loading';
import LoginDialog from './components/App/LoginDialog';
import { useUserStore } from './stores/useUserStore';

const RoomPage = lazy(() => import('./pages/RoomPage'));

function App() {
	const name = useUserStore(state => state.name);

	return (
		<>
			<Router>
				<Suspense fallback={<Loading />}>
					<Switch>
						<Route path="/room/:id">{name && <RoomPage />}</Route>
						<Route>
							<Header />
							<Container>
								<Routes />
							</Container>
							<BottomNavigationBar />
						</Route>
					</Switch>
				</Suspense>
			</Router>
			<LoginDialog />
		</>
	);
}

export default App;
