import React from 'react';
import { ThemeProvider } from './ThemeProvider';
import { Provider } from 'jotai';
import NotificationProvider from './NotificationProvider';

interface ProvidersProps {}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
	return (
		<Provider>
			<ThemeProvider>
				<NotificationProvider>{children}</NotificationProvider>
			</ThemeProvider>
		</Provider>
	);
};

export default Providers;
