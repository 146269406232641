import React from 'react';
import {
	BottomNavigation,
	BottomNavigationAction,
	makeStyles
} from '@material-ui/core';
import { Home, PermContactCalendar, Settings } from '@material-ui/icons';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		width: '100%'
	}
}));

interface BottomNavigationBarProps {}

const BottomNavigationBar: React.FC<BottomNavigationBarProps> = () => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const { t } = useTranslation();

	return (
		<BottomNavigation value={pathname} className={classes.root}>
			<BottomNavigationAction
				component={Link}
				to="/"
				label={t('footer.home')}
				value="/"
				icon={<Home />}
			/>
			<BottomNavigationAction
				component={Link}
				to="/contact"
				label={t('footer.contact')}
				value="/contact"
				icon={<PermContactCalendar />}
			/>
			<BottomNavigationAction
				component={Link}
				to="/settings"
				label={t('footer.settings')}
				value="/settings"
				icon={<Settings />}
			/>
		</BottomNavigation>
	);
};

export default BottomNavigationBar;
