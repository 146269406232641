import React from 'react';
import { Box, makeStyles, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		overflowY: 'auto'
	}
}));

interface ContainerProps {}

const Container: React.FC<ContainerProps> = ({ children }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root} component="main">
			<Toolbar />
			{children}
		</Box>
	);
};

export default Container;
