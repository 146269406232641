import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import zhHans from './locales/zh-Hans/translation.json';

export const resources = {
	en: {
		translation: en
	},
	'zh-Hans': {
		translation: zhHans
	}
};

export const init_i18n = () => {
	i18n
		// https://github.com/i18next/i18next-browser-languageDetector
		.use(LanguageDetector)
		// pass the i18n instance to react-i18next.
		.use(initReactI18next)
		// init i18next
		// see opts @ https://www.i18next.com/overview/configuration-options
		.init({
			resources,
			fallbackLng: 'en',
			debug: process.env.NODE_ENV === 'development',
			interpolation: {
				escapeValue: false
			},
			react: {
				useSuspense: false
			}
		})
		.then(() => {
			document.documentElement.setAttribute('lang', i18n.language);
		});
	i18n.on('languageChanged', lng => {
		document.documentElement.setAttribute('lang', lng);
	});
};
