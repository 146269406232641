import create from 'zustand';
import { persist } from 'zustand/middleware';

type UserStoreState = {
	name: string;
	setName: (name: string) => void;
};

export const useUserStore = create<UserStoreState>(
	persist(
		set => ({
			name: '',
			setName: name => set({ name })
		}),
		{
			name: 'user'
		}
	)
);
