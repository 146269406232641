import { atom } from 'jotai';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export const darkModeAtom = atom(false);

export const muiThemeAtom = atom(get => {
	const darkMode = get(darkModeAtom);
	return createMuiTheme({
		palette: {
			primary: {
				main: darkMode ? '#90caf9' : '#3f51b5',
				contrastText: '#fff'
			},
			background: {
				paper: darkMode ? '#1f1f1f' : '#fff',
				default: darkMode ? '#141414' : '#fafafa'
			},
			type: darkMode ? 'dark' : 'light'
		}
	});
});

export const roomSettingsOpenAtom = atom(false);

export const fullscreenAtom = atom(false);

export const joinRoomDialogOpenAtom = atom(false);
