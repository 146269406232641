import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Grow, makeStyles } from '@material-ui/core';
import { SnackbarUtilsConfigurator } from '../notifications';

const useStyles = makeStyles(theme => ({
	root: {
		'& > div': {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.text.primary
		}
	},
	containerRoot: {
		'& .MuiCollapse-wrapper': {
			justifyContent: 'center'
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '2em',
			width: 'auto'
		}
	}
}));

interface NotificationProviderProps {}

const NotificationProvider: React.FC<NotificationProviderProps> = ({
	children
}) => {
	const classes = useStyles();

	return (
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			autoHideDuration={3000}
			preventDuplicate
			classes={classes}
			//@ts-ignore
			TransitionComponent={Grow}
		>
			{children}
			<SnackbarUtilsConfigurator />
		</SnackbarProvider>
	);
};

export default NotificationProvider;
